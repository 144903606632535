<template>
    <div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PetuCustomer } from '@/classes/customer';
import { _st } from '@/softech';
export default {
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
    },
    async mounted() {
        if( _st.isNU( this.user ) ) {
            this.$router.push('/');
            return;
        }

        // check if customer exist with the same email address
        let customer = new PetuCustomer();
        await customer.loadFromEmail( this.user.attributes.email );

        if( _st.isEmpty( customer.data.id ) ) {
            customer.data.firstName = this.user.attributes['given_name'];
            customer.data.lastName = this.user.attributes['family_name'];
            customer.data.phone = _st.isNUE( this.user.attributes['phone_number'] ) ? null : _st.toPhoneNumber( this.user.attributes['phone_number'].replace('+1', '') );
            customer.data.email = this.user.attributes['email'];
            
            await customer.save();
            await customer.getProfilePicSrc();
        }
        else {
            customer.data.firstName = this.user.attributes.given_name;
            customer.data.lastName = this.user.attributes.family_name;
            customer.data.email = this.user.attributes.email;
            await customer.save();
        }

        this.updateCustomer({ customer });

        // go to home page
        let returnUrl = localStorage.getItem('returnUrl');
        if( !_st.isNUE( returnUrl ) ) {
            this.$router.push(returnUrl);
            localStorage.removeItem('returnUrl')
        }
        else
            this.$router.push('/');
    },
    methods: {
        ...mapActions({ updateCustomer: 'auth/updateCustomer' }),
    }
}
</script>